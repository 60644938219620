<template>
  <tr :class="{'persons__row-inactive': props.item.person.status == 'inactive'}" @click="goToRouteDetail()">
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-left" style="cursor: pointer;">
      {{props.item.person.id}}
    </td>
    <td class="justify-left" style="cursor: pointer;">
      <strong>{{props.item.person.name}}</strong>
    </td>
    <td class="justify-left" style="cursor: pointer;">
      {{props.item.person.cpf_cnpj}}
    </td>
    <!-- <td class="justify-left" style="cursor: pointer;">
      {{props.item.person.email}}
    </td> -->
    <td class="justify-left" style="cursor: pointer;">
      <span v-if="phones && phones.length > 0">
        <span v-for="(phone, proneIndex) in phones" :key="proneIndex">
          <span v-if="proneIndex">
            <br>
          </span>
          <span>{{ phone.contact | mask('(##) # ####-####') }}</span>
        </span>
      </span>
      <span v-else>- - -</span>
    </td>
    <td class="justify-left" style="cursor: pointer;">
      <span v-if="adresses && adresses.length > 0">
        <span v-for="(address, addressIndex) in adresses" :key="addressIndex">
          <span v-if="addressIndex">
            <br>
          </span>
          <span>{{ address.address }} n° {{ address.number }}, {{ address.district }}. </span>
          <span> {{ address.zip_code }}, {{ address.city.name_and_state }} </span>
        </span>
      </span>
      <span v-else>- - -</span>
    </td>
    <td class="justify-left" style="cursor: pointer;" @click="openPerson()">
      <span v-if="props.item.person.status == 'active'" class="persons__status persons__status--active">
        Ativo
      </span>
      <span v-else class="persons__status persons__status--inactive">
        Inativo
      </span>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'GraduationPersons',
    computed: {
      person () {
        return this.props?.item?.person || {}
      },
      phones () {
        return this.props?.item?.person?.phones || {}
      },
      adresses () {
        return this.props?.item?.person?.adresses || {}
      }
    },
    methods: {
      goToRouteDetail: function () {
        this.$router.push({name: 'customer', params: {id: this.props.item.person.id}})
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
  .persons__row-inactive {
    background: rgb(209, 209, 209);
  }
  .persons__status {
    border-width: 1px;
    border-style: solid;
    padding: 2px 5px;
  }
  .persons__status--active {
    color: #eceff1;
    background: rgb(4, 112, 0);
  }
  .persons__status--inactive {
    color: grey;
    background: #eceff1;
  }
</style>